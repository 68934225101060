.headerSubtext {
  color: #4e4d52;
  font-family: SF Pro Display;
  font-size: 0.4375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.525rem;
  margin-left: 20px;
}
.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 3px;
}

.mobileHeaderAuth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hideButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-right: 20px;
}
.headerAuthText {
  color: #fff;
  font-family: 'Poppins';
  font-size: 1.358rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.88738rem; /* 212.618% */
  letter-spacing: -0.08488rem;
}
