.meetingSettingsContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;
}

.meetingSettingsCard{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.meetingSettingsButtonsRow{
    margin-top: 15px;
    margin: 10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.meetingSettingsPanel__categoryIconBorder{
    width: 45px;
    height: 45px;
    border: 1px solid white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.meetingSettingsPanel__categoryIcon{
    width: 60%;
}