.partTwosideMenuItem{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 12%;  
}
.partTwosideMenuItem-hovered{
    display: flex;
    cursor: pointer;
    margin-top: 7%;
    align-items: center;
    margin-left: 25px;

}
.partTwosideMenuItem:first{
    margin-top: 7%;
}
.secondPartSideMenu{
    width: 85px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.secondCounter{
    color: white;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
}
.secondCounterWrapper{
    position: absolute;
    left: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid white;
    width: 19px;
    height: 19px;
    background: rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(1px);
}
.selectedItem{
    border: 2px solid #764DEF!important;
}

.secondImg{
 width: 16px;
 height: 16px;
}

.secondImgWrapper{
    border: 2px solid #222328;
    padding: 6.5px;
    border-radius: 6.5px;
    background:#222328;
}
.secondImgWrapper-active{
    padding: 6.5px;
    border-radius: 6.5px;
    border: 2px solid #764DEF!important;
    background-color: #222328;
  }


.firstSideMenuMainPage{
    width: 85px;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #28293D;
   
   height: max-content;
    top:60px;
   
    left: 0;
    background-color: #0C0D14;
    padding-bottom: 20px;
     border-bottom: 1px solid #28293D;
}


.firstSideMenuMainPage-openedMenu{
    width: 170px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #28293D;
    height: max-content;
    top:60px;
    z-index: 50;
   
    left: 0;
    background-color: #0C0D14;
    -webkit-transition: visibility 0s,all 0.05s ease-in-out;
     transition: visibility 0s,all 0.05s ease-in-out;
     border-bottom: 1px solid #28293D;
     padding-bottom: 20px;
     border-bottom-right-radius: 8px;

}

.secondSideMenuMainPage{
    border-right: 1px solid #28293D;
    border-top: 3px solid;
    /* padding-top: 10px; */
    padding-bottom: 10px;
    width: 85px;
    left: 0;
    background-color: #0C0D14;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.secondSideMenuMainPage-openedMenu{
    width: 170px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #28293D;
    border-bottom:1px solid #28293D ;
    border-top: 1px solid #28293D;
    z-index: 50;
    left: 0;
    /* top: -1%; */
    background-color: #0C0D14;
    /* margin-top: 0px; */
    /* -webkit-transition: visibility 0s,all 0.03s ease-in-out;
     transition: visibility 0s,all 0.03s ease-in-out; */
     /* border: 1px solid #28293D; */
     /* padding-top: 10px; */
     padding-bottom: 10px;
     border-bottom-right-radius: 8px;
     border-top-right-radius: 8px;

}

.sideMenuWrapperBlock{
    flex-direction: column;
    display: flex;
    position: fixed;
    padding-bottom: 0;
    height: 100%;
    width: 85px!important;
    z-index: 10000;
}

.tgBtn{
    border-right: 1px solid #28293D;
    height: 100%;
    width: 85px;
    left: 0;
    background-color: #0C0D14;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mobileSideMenuTextSecond{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: white;
    padding: 0;
    margin-left: 12px;
    margin-right: 12px;
}