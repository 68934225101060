
.mainPageWrapper {
  height: -moz-calc(100% - 46px);
  height: -webkit-calc(100% - 46px);
  height: calc(100% - 46px);
  margin-top: 50px;
  /* padding-top: -10px; */
  border-width: 0;
  border: 0;
  overflow-x: none;
}
.fullScreen {
  margin-top: 0px !important;
  height: 100% !important;
}
.mainPageWrapper::before {
  padding: 0;
  border-width: 0;
  border: 0;
}

.textMobileDemo {
  display: none;
}

@media screen and (max-width: 600px) {
  .textMobileDemo {
    position: absolute;
    bottom: 210px;
    padding: 10px 20px;
    border-radius: 9px;
    width: 60%;
    background-color: white;
  }
}

.contentWrapperMainPage {
  display: flex;
}
.sideMenuMainPage-opened {
  width: 200px;
}

.sidemenuItemtext {
  margin-left: 10px;
  color: white;
}

.squares {
  padding: 20px;
  flex-wrap: wrap;
  align-items: start;
  display: flex;
}

.oneSquare {
  width: 200px;
  height: 118px;
  background-color: #a48eff;
  border-radius: 10px;
  margin: 10px;
}

.contentMobileWrapper {
  width: calc(100%-85px);
  width: -moz-calc(100%-85px);
  width: -webkit-calc(100%-85px);
  margin-left: 85px;
}

.contentMobileWrapperMeetingOn {
  width: 100%;
  margin-left: 0;
  overflow: hidden !important;
}

.longTextFieldRecieved {
  top: 15px !important;
}
@media screen and (max-width: 600px) {
  .modalContainer {
    max-width: 70% !important;
  }
  .sideMenuWrapperBlock {
    display: none !important;
  }
  .mainViewMainPage,
  .paidRoomsPageWrapper {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .mainViewMainPage {
    margin-bottom: 10px !important;
  }
  .meetingCardsAllWrapper,
  .paidRoomsContentWrapper {
    width: 96% !important;
  }
  .meetingCards,
  .paidRoomMeetingCards {
    padding: 0px !important;
    flex-wrap: wrap;
    justify-content: space-around !important;
  }

  .paidRoomsContentWrapper {
    flex-direction: column;
    margin-top: 0px !important;
  }
  .paidRoomsPricings {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 50px;
    margin-right: 0 !important;
  }

  .paidRoomsPricings:first-child {
    margin-left: 0px;
  }
  .link,
  .link__defaultImage,
  .link__recieved,
  .loaderCard,
  .paidLoaderCard,
  .paidd-card {
    width: 48% !important;
    margin-right: 0px !important;
    height: calc(47vw / 1.67) !important;
    margin-bottom: 10px;
  }
  .sectionSelectorBoxWrapper {
    justify-content: flex-start !important;
    width: 93%;
    margin: 0 auto;
    padding-bottom: 0px !important;
    border-radius: 7px;
    width: 99.5% !important;
    /* border: 1px solid #353840; */
    overflow-x: scroll;
    margin-bottom: 15px !important;
  }
  .css-19r6kue-MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sectionSelectorContent {
    border: 0 !important;
    border-radius: 0 !important;
  }

  .sectionSelectorBoxWrapper::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .sectionSelectorBoxWrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .meetingCards:last-child {
    margin-bottom: 20px;
  }

  .link__recieved > .cardHeader {
    height: 100%;
  }
  .cardData__recieved {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
  }

  /* .textField__recieved{
            top:15px!important;
        } */

  .longTextFieldRecieved {
    top: 15px !important;
  }
  .link__recieved--paid > .cardHeader {
    height: 100%;
    width: 100%;
  }
  .cardData__recieved--paid {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
  }

  /* .link__recieved--paid > .textField__recieved{
            top:15px;
        } */

  .textField {
    font-size: 12px;
    font-family: Nunito;
    font-weight: 800;
    line-height: 18.245px;
    margin-top: 10px;
  }
  .cardData {
    width: 29px;
  }
  .meetingCards:last-child {
    margin-bottom: 0px;
  }

  /* .firstWrapper > .meetingCards:nth-child(2) > .loaderCard:nth-child(2){
          margin-bottom: 50px;
    }
    .firstWrapper > .meetingCards:nth-child(2) > .freee-card:nth-child(2){
        margin-bottom: 50px;
      }
      
      .secondWrapper > .meetingCards:first-child > .loaderCard:nth-child(4){
        margin-bottom: 50px;
      }

      .secondWrapper > .meetingCards:first-child > .freee-card:nth-child(4){
        margin-bottom: 50px;
      } */

  .streamPriceBox--pink > .streamPrice {
    color: rgba(173, 0, 255, 1);
  }

  .streamPrice {
    font-size: 9px;
    font-family: Poppins;
    font-weight: 500;
  }
  .streamPriceBox--purple > .streamPrice {
    color: rgba(0, 163, 255, 1);
  }

  .paidd-card:nth-child(7) {
    margin-bottom: 0px !important;
  }
  .paidd-card:nth-child(8) {
    margin-bottom: 0px !important;
  }

  .paidRoomsPricings:last-child {
    margin-bottom: 0px;
  }

  .link--paid {
    margin-bottom: 10px !important;
  }
}
.magicItemsWrapper {
  display: none;
}
.magicItemsWrapperOn {
  display: block;
  background: linear-gradient(134deg, #240a51 0%, #5201c8 100%);
  position: absolute;
  z-index: 21000;
  bottom: 80px;

  width: 17em;
  height: 9em;

  border-radius: 6px;
}
@media screen and (max-width: 600px) {
  .magicItemsWrapperOn {
    display: block;
    background: linear-gradient(134deg, #240a51 0%, #5201c8 100%);
    position: absolute;
    z-index: 21000;
    bottom: 200px;
    left: 15px;
    width: 17em;
    height: 9em;

    border-radius: 6px;
  }
}
.magicItemsContent {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 17em;
  max-height: 60px;
}

.magicItemItem {
  cursor: pointer;
  position: relative;
  padding: 13px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.14);
}
.magicItemItem:nth-child(1n + 6) {
  margin-top: 10px;
}

.magicItemCounter {
  position: absolute;
  top: 22%;
  left: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  height: 22px;
  display: flex;
  width: 22px;
}

.magicItemCounterIndex {
  color: white;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

.magicItemItem > p {
  font-size: 20px;
}
