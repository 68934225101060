.widgetWrapper{
    width:auto;
    display: block;
    position: fixed;
    margin: 0 auto;
    z-index: 9;
    bottom: 3%;
    left: 3.5%;
    
}
.widgetWrapper::before{
  content: "";
  position: absolute;
  display: block;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  border-radius: 50%;
}
/* .widgetButton{
   
    height: 63px;
    width: 93px;
    background: linear-gradient(150deg, #303030 0%, #1C1C1C 100%);
   border-radius: 20px;
} */

.closedWidget{

}
@keyframes ani {
  0% {transform: translateY(150%);}
  100% {transform: translateY(0);}
}
.box {
  position: relative;
}
.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px; 
  padding: 2px; 

  background:linear-gradient(148deg, #212121, #1C1C1C ); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}


@keyframes rotating {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotatingMinus {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.rotating {
  animation: rotating 12s linear infinite;
}
.rotatingMinus {
  animation: rotatingMinus 
  12s linear infinite;
}
h1 span {
  font: 10px Monaco, MonoSpace;
  color: white;
  height: 10px;
  position: absolute;
  width: 120px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
}
.char1 { transform: rotate(12deg); }
.char2 { transform: rotate(24deg); }
.char3 { transform: rotate(36deg); }
.innerWrapperWidget{
  background-color: black;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  border-radius: 50%;
}
.activeButtonWidgetWrap{
 
  text-align: justify;
  width: 65px;
  height: 65px;
  background-image: url("./siri2.gif") ;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
  /* background: linear-gradient(180deg, #6849EE 0%, #AC60F7 100%); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeButtonImage{
  width: 20px;
 

}
.openedIconMargin{
   margin-top:5px;
}
.widgetMenuItems{
  width: fit-content;
  position: absolute;
 bottom: 100%;
  height: auto;
  padding-bottom: 25px;
  transform: translateY(150%);
  animation: ani 0.5s forwards;
  
}
.countryButtonWrapper{
  display: flex;
  width: max-content;
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
}
.countryButton{
  height: 47px;
  border-radius: 9px;
  border:2px solid #101010;
  background-color: #101010;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
}

.countryButtonimage{
  width: 22px;
  height: 22px;
}
.countryButtonTextWrapper{
  width: auto;
  height: auto;
  border-radius: 5px;
background-color: rgba(16, 16, 16, 0.5);
margin-left: 10px;
padding: 4px 10px;
display: flex;
justify-content: center;
align-items: center;
}
.countryButtonText{
color: #FFF;
font-family: "SF Pro Display Semibold";
font-size: 13px;
font-style: normal;
font-weight: 600;


}
.activeCountry{
  border: 2px solid #6E4BEF;
}