.tipIconSmall:hover{
    scale: 1.2;
}

.tipIconSmall{
    cursor: pointer;
}

.openedTipMenu{
    background: linear-gradient(131.58deg, #240A51 4.63%, #5201C8 103.21%);
}