.backgroundWraper{
    position: absolute;
    width: 43px;
    height: 28px;
    left: 5%;
    bottom: 10%;
    flex-shrink: 0;
}


.backgroundWraperPrice{
    position: absolute;
    width: 65px;
    height: 40px;
    right: 3.7%;
    bottom: 6%;
    flex-shrink: 0;
}


.backgroundLayer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-6.772deg);
   background: rgba(80, 80, 80, 0.2);
    border-radius: 25px;
    backdrop-filter: blur(8px);
}

.backgroundLayerPrice{
    width: 100%;
    position: relative;
    height: 100%;
       border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-6.772deg);
    background: linear-gradient(148deg, #515151, #181818);
background-clip: padding-box; 
   border: solid 2px transparent;
  

   
}
.backgroundLayerPrice::before{
     content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 1.2px solid transparent;
   background:linear-gradient(148deg,  #807f7f, #181818) border-box; 
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.participantsOnlineContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: rotate(7deg);
    padding: 10px;
    color: white;
}

.onlineCountText{
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "SF Pro Display Black";
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    padding-right: 2px;
    letter-spacing: -0.0525rem;
    text-transform: uppercase;
}

.meetingPriceText{
    color: #FFF;
font-family: "SF Pro Display Black";
font-size: 16px;
font-style: normal;
font-weight: 900;
line-height: 24.783px; /* 130.435% */
letter-spacing: 0px;
}

@media screen  and (max-width:768px){
   
    .backgroundWraper{
     width: 28px;
height: 19px;
left: 5%;
    }
    .onlineCountText{
        font-size: 8px;
    }
    .backgroundWraperPrice{
        width: 45px;
height: 32px;
    }
    .meetingPriceText{
          font-size: 10px;
    }
    .onlineLogoMan{
        width: 9px;
    }
   
}