
.controls-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.one_minute{
    border-color: black;
    padding: 0 8px!important;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    text-overflow: ellipsis;
    display: block;
    text-align: center;
    flex-shrink: 0;
    height: 32px;
    line-height: 30px;
    max-width: 200px;
    padding: 0 11px;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 6px;

    color:  #333333;
    border: 1px solid rgba(0, 0, 0, .8);
    background-clip: padding-box;
}

.one_minute__apply{
    border-color: black;
    padding: 0 8px!important;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    text-overflow: ellipsis;
    display: block;
    text-align: center;
    flex-shrink: 0;
    height: 32px;
    line-height: 30px;
    max-width: 200px;
    padding: 0 11px;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 6px;

    color:  #333333;
    border: 1px solid rgba(0, 0, 0, .8);
    background-clip: padding-box;
    background:  rgba(102,255,153,1.00)
}
.playback_control_buttons{

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
}

.playback_control_button__start{
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9747ff;
}
.playback_control_button__start:hover{

    background: #8638e5;
}

.playback_control_button__pause, .playback_control_button__stop{
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid  #e6e6e6;
    color: rgba(0, 0, 0, .8);
    color: black;
}
.playback_control_button__pause:hover, .playback_control_button__stop:hover{
    background: #f5f5f5
}



  .playIcon{
    fill: rgba(255, 255, 255, 1);
  }

