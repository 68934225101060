.bottomBarPreviewImage {
  width: 45px;
  height: 45px;
  border-radius: 7px;
  cursor: pointer;
  margin: 0.5em;
  object-fit: cover;
}
.payButton__text {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.3967544436454773px;
  text-align: left;
  color: white;
}

.bottomBarMainWrapper {
  position: relative;
  bottom: 20px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .bottomBarMainWrapper {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .bottomBarMainWrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .bottomBarMainWrapper {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

/* .bottomBarFullscreen {
  display: none;
} */

.bottomBarFullscreen {
  animation-name: dissapearSmooth;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.mobileBottomBar {
  justify-content: flex-start !important;
  width: 93%;

  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  /* margin-top: 80vh; */
  flex-direction: row-reverse;
}

.wrapperMobileBarFullPageWrapper {
  position: absolute;
  width: 100%;
  /* height: calc(80vh + 60px); */
}

.relWrapperForBottomBar {
  /* width: 100%; */
  height: 93vh;
  display: flex;
  margin: 0 auto;
  overflow-x: scroll;
}

.mobileBottomBarContent {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 7px;
  padding: 7px;
  position: relative;
  /* bottom: 60px; */
  /* margin-top: 75vh; */
}
