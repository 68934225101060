.amountButton{
    display: flex;
    width: 80px;
    height: 70px;
    letter-spacing: 0.5px;
    background-color: #E0314B;
    color: white;
    font-size: 13px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
    margin-right: 7px;
    margin-bottom: 6px;
}

.active{
    border: 1px solid #FFFFFF;
}

.selectTipsAmountWrapper{
    display: flex;
}

.buttonPriceInfo{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 60%;
}
.tipAmount{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    background: rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(1px);
    border-radius: 9px;
    margin-bottom: 4px;
}