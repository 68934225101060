.loaderCard{
   height: -webkit-calc((100vw * 0.157) / 1.8);
    height: -moz-calc((100vw * 0.157) / 1.8);
    height: calc((100vw * 0.157) / 1.8);
    border-radius: 8.5px;
   width: 13.25%; 
   margin-bottom: 15px;
   background-color: transparent;
   position: relative;
   z-index: 1;
}
@media screen  and (max-width:600px){
   .loaderCard{
margin-bottom: 15px;
   } 
    
}

.paidLoaderCard{
    width: 48%;
position: relative;
margin-bottom:15px;
height: calc(15vw / 1.8);
height: -moz-calc(15vw / 1.8);
height: -webkit-calc(15vw / 1.8);
background-color: #26262B;
border-radius: 8.5px;
}

.loaderCardSkelet{
    height: inherit!important;
    border-radius: 8.5px;
   width:100%; 
   margin-bottom: 15px;
   background-color: #2c2c2f!important;
   z-index: 10000!important;
}

.textFieldSketet{
    position: absolute;
    top: -2px;
    font-family: "SF Pro Display Medium";
    font-style: normal;
  left: 0;
  right: 0;
    border-radius: 3px;
    margin: 0 auto;
    height: 20%;
   
    line-height: 1.3em;
    padding: 5px 8px;
   
    background: transparent;
    width: 90%;
}
.textFieldSketet> .loaderCardSkelet{
    height: 150%!important;
    position: absolute;
    top: -2px !important;
    right: 0;
    background-color: #1d1d20!important;
}

.imageSkeleton{
    
    width: 43px;
    height: 28px;
    position: absolute;
    bottom: 10px;
    border-radius:50%;
    left: 10px;
    transform: rotate(-7deg);
    z-index: 100000;
}
.imageSkeleton2{
    
   width: 65px;
    height: 40px;
    position: absolute;
    bottom: 6%;
    border-radius:50%;
    right: 3.7%;
    transform: rotate(-7deg);
    z-index: 10000000;
}
.imageSkeleton > .loaderCardSkelet{
    height: 150%!important;
    position: absolute;
    border-radius: 25px;
    top: -3px !important;
    right: 0;
    height: 100%!important;
    background-color: #232327!important;
}

.imageSkeleton2 > .loaderCardSkelet{
    height: 150%!important;
    position: absolute;
    border-radius: 25px;
    top: -3px !important;
    right: 0;
    z-index: 1000000;
    height: 100%!important;
    background-color: #232327!important;
}

@media screen and (max-width:600px){
    .textFieldSketet{
        top: -6.5px!important;
    }
}

.avatarSkelet{
    width: 25px;
       position: absolute;
    height: 25px;
    top: 35%;
    bottom: 0;
    /* margin: auto 0; */
    margin: auto 0;
    border-radius: 8px;
 
     background-color: #2c2c2f!important;
     z-index: 100000;
}

.avatarSkelet > .loaderCardSkelet{
    z-index: 10000000;
    margin: 0;
}

@media screen  and (max-width:600px){
    .avatarSkelet{
        top:25%;
    }
    .imageSkeleton2{
        width: 45px;
        height: 32px;
    }
    .imageSkeleton{
        width: 28px;
        height: 19px;
        left: 5%;
    }
}