.mobileMeetingsSwitchWrapper{display: none;}

@media screen and (max-width:600px) {
    .mobileMeetingsSwitchWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    padding-top: 30px;
    }
    .mobileMeetingsSwitchContent{
        display: flex;
        width: 140px;
        justify-content: space-between;
    }

    .shortMobileSwitch{
        width: 95px;
    }

    .newButtonWrapper > .newButtonText{
        display: none;
    }
    .newButtonWrapper > .newButtonImage{
        margin-left: 0;
    }
    .newButtonWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobileMeetingsSwitchContent > a{
        margin-right: 0px!important;
    }

    .mainNavBar{
        display: none!important;
    }
}