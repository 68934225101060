.onlineAvatarWrapper{
    /* position: absolute; */
    width: 25px;
    height: 25px;
    background: linear-gradient(139.11deg, #6E4AEE 2.01%, #A65DF5 99.33%);
    border-radius: 8px;
    left: 5%;
    bottom: 7%;
    margin-right:6px;

    /* position: relative; */
}

.onlineUserAvatar{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #FFF;
}

.onlineMark{
    width: 10px;
    height: 10px;
    border: 1.5px solid white;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    z-index: 10000;
    right:  -4px;
    bottom: -2px;
}

@media screen  and (max-width:768px){
   
    .onlineAvatarWrapper{
        width: 24px;
        height: 24px;
    }
}