.tipsSelectorBoxWrapper{
    /* margin-top: 10px; */
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-items: space-between;
    background: linear-gradient(131.58deg, #240A51 4.63%, #5201C8 103.21%);
    border-radius: 7px;
    padding: 10px;
    width: 100%;
}