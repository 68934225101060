.sectionSelectorBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 15px;
  padding-top: 25px;
  position: relative;
}
.sectionSelectorContent {
  margin-right: auto;
  background-color: transparent;
  overflow-x: scroll;
  display: flex;
  align-items: flex-start;

  position: relative;
  scroll-behavior: smooth;
}
.sectionSelectorContent::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.scrollButton {
  position: absolute;
  transform: translate(0, -40%);
  z-index: 200000;
  cursor: pointer;
  padding-left: 100px;
  /* -webkit-box-shadow: -64px 0px 100px 79px rgba(34, 60, 80, 0.2);
-moz-box-shadow: -64px 0px 100px 79px rgba(34, 60, 80, 0.2);
box-shadow: -64px 0px 100px 79px rgba(34, 60, 80, 0.2); */
}
.toRight {
  right: 0;
}
.toLeft {
  left: 0;
  transform: rotate(180deg) translate(0, 40%);
}
.friends {
  max-width: fit-content;
}
.sectionSelectorContent {
  /* display: flex;
    align-items: center;
    justify-content: center;
    background: #0C0D13;
    /* border: 1px solid #353840;
    border-radius: 7px;
    padding: 7px;
    margin-left: 25px; */
}

.onePageSelector {
  margin-left: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sectionSelectorBox {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 8px;
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  .sectionSelectorContent {
    margin-left: auto;
  }
}
@media screen and (min-width: 600px) {
  .sectionSelectorBox:first-child {
    padding-left: 0px;
  }
  /* .sectionSelectorBox:last-child{
    margin-right: 0px;
} */
}

.sectionName {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 23.494px;
  display: flex;
  margin-top: 7px;
  align-items: center;
  color: rgba(255, 255, 255, 0.35);
}

.hoveredSectionName {
  color: '#fff';
}
.selectedUnderline {
  transition: opacity 0.2s cubic-bezier(0.2, 0, 0, 1);
  height: 1px;
  margin-top: 10px;
  background: white;
  width: 100%;
}
.transparentSelectedUnderline {
  height: 1px;
  margin-top: 10px;
  background: transparent;
  width: 100%;
}
.selectedUnderlineHovered {
  background: gray;
  opacity: 0.5;
}

.tabs {
  font-size: 2.25rem;
}

.css-1wpgw4s,
.css-11guvvc {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-o5t0wc-MuiTabs-root .MuiTabs-scrollButtons {
  width: 20px;
  font-size: 2.25rem;
}

.sectionSelectorContainer {
  width: 87%;
}
@media screen and (max-width: 600px) {
  .sectionSelectorContainer {
    width: 100%;
  }
  .sectionSelectorBox {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0;
    margin-left: 0;
    z-index: 999999;
  }
  .selectedUnderline {
    margin-top: 10px;
  }
  .transparentSelectedUnderline {
    margin-top: 10px;
  }
  .scrollButton {
    display: none;
  }
}

.sectionButtonImageBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  border-radius: 12px;
  height: 43px;
  background: linear-gradient(92deg, #161616, #101010);
  position: relative;
}

.sectionButtonImageBackground::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1.2px;
  background: linear-gradient(130deg, #565656, #101010 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
