.stripeConnect__wrapper{
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 7px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between;

    width: 90%;
}

.stripeConnect__logo{
    width: 28% !important;
    border-radius: 5px;
}

.stripeConnect__info{
font-family: 'Poppins';
font-size: 10px;
font-weight: 600;
line-height: 23px;
letter-spacing: -0.3967544436454773px;
text-align: left;
color: rgba(255, 255, 255, 1);
padding-right: 7%;

}