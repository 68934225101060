
  .modalButton {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 12px 24px;
  }
  .ownModalImage{
    margin-top: 7%;
    width: 100%;
  }
  /* .ownModalHeadLine {
    height: 20px;
    background: linear-gradient(131.58deg, #240A51 4.63%, #5201C8 103.21%);
  }
   */
  
  /* Modal */
  /* .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    margin-top: 50px;
    width: 100%;
    height: 100%;
  } */
  .overlay {

      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      opacity: 0.0;
      z-index: 10000;
      background: rgba(0, 0, 0, 0.2);
      display: none;

}
  
  .modalContainer {
    margin-top: 7%;
    max-width: 25%;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: hsba(240, 35%, 16%, 1);
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
  }

  .ownModalWrap{
    border-radius: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }
  

  
  @media screen and (max-width: 500px) {

  }