.mobileShareButton {
  display: flex;
  align-items: center;
  height: 36px;
  top: 51px;
  left: 197px;
  padding: 7px, 24px, 7px, 22px;
  border-radius: 8px;
  gap: 7.62px;
  background: linear-gradient(118deg, rgba(107, 74, 235, 1), rgba(172, 91, 243, 1));
  color: white;
}

.mobileShareContent {
  display: flex;
  align-items: center;
  padding: 7px 22px;
}

.mobileShareText {
  top: 7px;
  left: 46px;
  font-family: 'SF Pro Display Black';
  font-size: 17px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: -1px;
  text-align: left;
}

.mobileShareIcon {
  width: 20.02px;
  height: 16.56px;
  margin-right: 3px;
}
