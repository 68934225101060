.meetingCards{
    display: flex;
    width: 95%;
    margin: 0 auto;
    height: fit-content;
    padding-top: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:600px){
    .meetingCards{
width: 100%!important;
    }
    
}
.backdrop {
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        position: absolute;				
        background-color: #cccccc;
        color: #aaaaaa;
        opacity: .4;
        filter: alpha(opacity = 50);
  }

.meetingCardsAllWrapper{
   width: 100%;
    padding-left: 0px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.meetingCards::-webkit-scrollbar {
  display: none;
}
.meetingsCardsHeadline{
    padding-left: 2%;
    font-family: "Nunito";
    font-weight: 800;
    color: #fff;
    font-size: 25px;
    line-height: 35px;
    margin-block: 0;
    margin-bottom: 2%;
}

.mainViewOwnPage{
    width: calc(100% - 85px);
    width: -moz-calc(100% - 85px);
    width: -webkit-calc(100% - 85px);
    margin-left: 85px;
    height: 700px;
}


.meetingCardsOwn{
    display: flex;
    width:100%;
    height: fit-content;
    padding-left: 20px;
    padding-top: 10px;
    justify-content: start;
}

.meetingCardsOwn > .link , .meetingCardsOwn > .link__recieved{
    width: 200px;
    height: 118px;
    margin: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.meetingCardsOwn > .link__recieved:hover{
    width: 200px;
    height: 118px;
    position: relative;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    background-color: transparent;
    margin-right: 15px;
    transform: scale(1.05)
}

.meetingCardsAllWrapper:nth-child(2){
    padding-top: 0;
}

@media screen  and (max-width:600px){
.meetingCardsAllWrapper{
    margin-top:30px;
}    
}