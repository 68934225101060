.headerWrapper {
  width: 100%;
  z-index: 1;
  margin-top: -50px;
  background: black;
  backdrop-filter: blur(8px);
  height: 60px;
  /* padding-top: 10px; */
  min-height: 60px;
  position: relative;
}

.meetingStarted {
  margin-top: -60px;
  background: transparent;
  border: 0px;
  position: relative;
  top: 60px;
}

.headerFulscreen {
  animation-name: dissapearSmooth;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.headerContent {
  width: 95%;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.headerText {
  color: white;
  padding-left: 3px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 34px;
  letter-spacing: -1px;
  cursor: pointer;
  margin-right: 10px;
}
.mainHeaderButtonsWrapper {
  display: flex;
  align-items: center;
}
.mainHeaderButtonsWrapper > a {
  margin-right: 30px;
}
.own {
  margin-right: 15px;
}
.free {
  margin-right: 15px;
}

.searchWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
  position: relative;
  color: white;
  height: 43px;
  border-radius: 7px;
  width: 15vw;
  /* margin-left: 42%; */
  background: linear-gradient(148deg, #181818 0%, #111 100%);
}
.searchWrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 7px;
  padding: 1px;

  background: linear-gradient(120deg, #383838 20%, #111 80%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.searchWrapperM {
  cursor: pointer;
  display: none;
  align-items: center;
  background: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: white;
  height: 43px;
  border-radius: 7px;
  width: 95%;
  position: relative;
  margin: 0 auto;
  top: 12px;
  background: linear-gradient(148deg, #181818 0%, #111 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.connectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* .searchWrapperM::before {
  content: "";

  position: absolute;
  display: none !important;
  inset: 0;
  border-radius: 7px;

  padding: 1px;
  background: linear-gradient(120deg, #383838 20%, #111 80%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;

          mask-composite: exclude; 
} */
.modalAuthWrapperDesktop {
  width: 20rem;
  height: 40rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9000000;
  right: 2.2rem;
  top: 80px;
  background: red;
  border-radius: 0.625rem;
  border: 1px solid #000;
  background: #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.balanceWrapper {
  display: flex;
  align-items: center;
  background: transparent;
  position: relative;
  justify-content: space-around;
  width: 200px;
  /* border: 2px solid #353840; */
  color: #858e96;
  height: 43px;
  border-radius: 7px;
  width: 10vw;
  padding: 5px;
}

.balanceWrapper::before {
  content: '';
  /* position: absolute; */
  inset: 0;
  border-radius: 7px;
  padding: 1px;
  background: linear-gradient(120deg, #2c2c2c 20%, #111 80%);

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.searchImg {
  margin-left: 10px;
}
.balanceImg {
  margin-right: 12%;
  border-left: 1px solid #353840;
  padding-left: 13%;
}
.searchText {
  font-family: 'SF Pro Display Medium';
  outline: none !important;
  font-style: normal;
  font-weight: 500;
  position: relative;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 14px;
  border: 0;
  z-index: 1002010;
  background-color: transparent;
  color: white;
  letter-spacing: 0.15px;
  width: 100%;
  --tw-ring-color: transparent;
}

.searchText::before {
  content: '';
  position: absolute;
  display: block;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
}

.searchTextReversed {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
}

.newButtonWrapper {
  width: 9vw;
  height: 160%;
  z-index: 20;
  background: transparent;
  position: relative;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.default {
  border: 1px solid white;
  color: white;
}
.defaulthovered {
  border: 1px solid black;
  color: black;
  background: white;
}
.lightPurple {
  border: 1px solid #ad00ff;
  color: white;
}
.lightPurplehovered {
  border: 1px solid #ad00ff;
  color: white;
  background: #ad00ff;
}
.purplehovered {
  border: 1px solid #6d4aee;
  color: white;
  background: #6d4aee;
}
.purple {
  border: 1px solid #6d4aee;
  color: white;
}
.newButtonImage {
  margin-left: 18px;
}

.newButtonText {
  margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
/* 
.logo-st {
    height: 29px;
    cursor: pointer;
    
}

.logo-revert{
    height: 29px;
    transform: rotate(180deg);
    cursor: pointer;
}


.logoBlock{
    display: flex;
    align-items: center;
}
.crazyLogo{
    height: 29px;
    padding-left: 20px;
}
.form{
    display: flex;
    height: 40px;
    transition: all 0.1s ease-in-out;
    width: 100%;
    min-width: 300px;
    border-radius: 20px;
    background-color:#373952;
    fill:#aaadbe;
    cursor: auto;
    color: #f9faff;
}

.textField{
    opacity: 1;
    font-family: 'Nunito';
    font-weight: 500;
    background: transparent;
    margin-left: 12px;
    outline: 0px;
    fill:#aaadbe;
    color: rgb(249, 250, 255);
    width: 100% !important;
    border-width: 0px;
    box-shadow: 0px 0px;
}

.textField::placeholder {
    color: rgb(249, 250, 255);
  }

  .textField:focus{
    outline: none !important; 
  }
.headerText{
    color: white;
    padding-left: 15px;
    font-size: 18px;
    font-family: Nunito;
    font-weight: 800;
    cursor: pointer;
}
.icon{
    width: 24px;
    margin-right: 15px;
}

@media screen and (max-width: 813px) {
    .form {
      display: none;
    }
}

@media screen and (min-width: 1000px) {
    .form {
      min-width: 370px;
    }
}

.likedButton{
    display: flex;
    align-items: center;
}

.likedButton > img{
    width: 20px;
    margin-right: 7px;

}
.lastButtons{
    display: flex;
    cursor: pointer;
}

.myStreamsButton{
    color: white;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 600;
    width: 115px
}

.myStreamsButton:hover{
    color: #EFF0F7;
} */

.connectWrapper {
  display: flex;
  z-index: 1;
  align-items: center;
  background: linear-gradient(118deg, rgba(107, 74, 235, 1), rgba(172, 91, 243, 1));

  /* position: relative; */

  color: white;
  height: 43px;
  border-radius: 8px;
  width: max-content;
  cursor: pointer;
  /* font-family: 'SF Pro Display Black';
  font-size: 17px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: -1px;
  text-align: left; */
}
/* .connectWrapperReversed{
    display: flex;
    align-items: center;
    background: transparent;
    border: 2px solid #353840;
    color: white;
    height: 43px;
    border-radius: 7px;
    width: 13vw;
    cursor: pointer;
} */

.connectWrapperReversed {
  display: flex;
  align-items: center;
  background: transparent;
  border: 2px solid #353840;
  color: white;
  height: 43px;
  border-radius: 7px;
  width: max-content;
  cursor: pointer;
}
.whiteConnect {
  background: white !important;
  position: relative;
}
.whiteConnect::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 7px;
  padding: 1px !important;
  background: linear-gradient(120deg, #5f5f5f 20%, #ffffff 80%) !important;

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* .connectContainer:hover > .connectWrapperReversed{
    display: flex;
    align-items: center;
    background: black;
    border: 2px solid black;
    color: white;
    height: 43px;
    border-radius: 7px;
    width: 13vw;
    cursor: pointer;
} */
/* .connectWrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 7px;
  padding: 1px;
  background: linear-gradient(120deg, #2c2c2c 20%, #111 80%);

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
} */
.searchTextReversed {
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 10px;
  padding-left: 10px;
}
/* .connectContainer:hover > .connectWrapper{
        display: flex;
        align-items: center;
        background: black;
        border: 2px solid white;
        color: white;
        height: 43px;
        border-radius: 7px;
        width: 13vw;
        cursor: pointer;
    } */

.connectContainer:hover > .searchText {
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
}
.connectContainer:hover > .connectWrapperReversed > .searchTextReversed {
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
}
.balanceText {
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-left: 12%;
}

.balanceTextReversed {
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-left: 12%;
}

.accountDataContainer {
  display: flex;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

input {
  outline: 0 !important;
}

.nameInput {
  outline: none !important;
  background: transparent;
  border: 1px solid #353840;
  border-radius: 6px;
  width: fit-content;
  color: white;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-right: 20px;
}
.nameInput::placeholder {
  color: white;
}
.searchText:active,
.searchText:hover,
.searchText:focus {
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: transparent;
}
.nameInput:active,
.nameInput:hover,
.nameInput:focus {
  border-radius: 6px;
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: white;
  width: fit-content;
}

.rev:active,
.rev:hover,
.rev:focus {
  border-radius: 6px;
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: black;
  width: fit-content;
}
.open {
  width: fit-content !important;
}

.small {
  height: 43px;
  width: 43px;
}

.big {
  width: 125px;
}

.hidden {
  display: none;
}

.phInpWrapper {
  position: relative;
  width: 43px;
  height: 43px;
  background: linear-gradient(139.11deg, #6e4aee 2.01%, #a65df5 99.33%);
  border-radius: 7px;
  margin-right: 20px;
  display: flex;
}

.phUserAvatar {
  width: 100%;
  height: 36px;
  border-radius: 7px;
}

.phInput {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.nameMeetingInputWrapper {
  background: white;
  border-radius: 12px;
  justify-content: space-around;
  width: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 90px;
  z-index: 2;
}

.nameMeetingInputWrapper:hover {
  border-color: rgb(69, 159, 189);
  box-shadow: 0px 0px 15px rgb(69, 159, 189);
  outline: 1px solid transparent;
}

.nameMeetingInput {
  border: 0;
  --tw-ring-color: '';
  padding-right: 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: black;
  background-color: transparent;
  width: 80%;
  height: max-content;
}
.nameMeetingInput:focus,
.nameMeetingInput:hover {
  --tw-ring-color: '';
  color: rgba(0, 0, 0, 0.3);
}

.meetNameImage {
  padding-right: 15px;
  padding-left: 15px;
}
.meetNameImage:hover {
  padding-right: 15px;
  padding-left: 15px;
  transform: scale(1.05);
}
.desktopNaw {
  display: flex;
}
.headerContentWpr {
  display: flex;
  width: 95%;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.contentForHeaderMeetingOn {
  display: flex;
  justify-content: start;
  height: 100%;
  align-items: center;
  padding-left: 3%;
  cursor: pointer;
}
.connectContainerMobile {
  display: none;
}
.arrowBackToMain {
  margin-right: 10px;
}
.submitUsername {
  position: absolute;
  top: 30%;
  left: 77%;
}

.textAret {
  resize: none;
  outline: none;
  height: 55px;
  z-index: 200;
}

.textAret::placeholder {
  color: white;
}

.mobileBeforeConnect {
  display: none;
}

/* .mobileAfterConnect {
  display: none;
  width: 30vw;
  justify-content: space-between;
} */

.smallButtonWrap {
  display: flex;
  align-items: center;
  background: transparent;
  border: 2px solid #353840;
  color: white;
  height: 10vw;
  border-radius: 7px;
  width: 10vw;
  justify-content: center;
  cursor: pointer;
}

.smallButtonImg {
  margin-left: 0px;
}

.mobileMainNav {
  display: none;
}

.mobileSideMenuWrapp {
  display: none;
}

.imgLoaderGif {
  position: absolute;
}
@media screen and (min-width: 600px) {
  .imgLoaderGif {
    height: 75%;
    position: absolute;
  }
}
.waitingScreenDSKMB {
  height: 93vh;
}
.countriesMobileSideMenu {
  display: none;
}
@media screen and (max-width: 600px) {
  .mobileAuthWrapper {
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9000000;
    bottom: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid #000;
    background: linear-gradient(118deg, rgba(18, 18, 18, 1), rgba(25, 14, 54, 1));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .searchWrapper {
    display: none;
  }
  .connectContainer {
    display: none;
  }
  .wrapperMobileBarFullPageWrapper {
    z-index: 0;
  }
  .connectContainerMobile {
    display: block;
  }
  .phInpWrapper {
    display: none;
  }
  .waitingScreenDSKMB {
    height: 76vh;
  }
  .bigNameInput {
    display: none !important;
  }
  .mobileBalanceText {
    padding-left: 2vw;
    color: white;
    font-size: 12px;
    font-family: Nunito;
    font-weight: 600;
    line-height: 23.494px;
    padding-right: 2vw;
  }

  .mobileBalanceTextReversed {
    padding-left: 2vw;
    color: black;
    font-size: 12px;
    font-family: Nunito;
    font-weight: 600;
    line-height: 23.494px;
    padding-right: 2vw;
    border-right: 1px solid #858e96;
  }
  .mobileBeforeConnect {
    display: flex;
    width: max-content;
    justify-content: space-between;
  }

  .mobileBalanceWrapper {
    display: flex;
    align-items: center;
    background: transparent;
    position: relative;
    justify-content: space-between;
    width: 200px;
    color: #858e96;
    height: auto;
    border-radius: 7px;
    width: fit-content;
  }
  .imgLoaderGif {
    height: auto;
    position: absolute;
  }
  .withContent {
    background: transparent;
  }
  .headerContent {
    margin: 0 auto;
  }
  .mobileAfterConnect {
    display: flex;
    align-items: center;
    /* width: 30vw;
    justify-content: space-between; */
  }
  .mobileMainNav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktopNaw {
    display: none;
  }
  .mobileMainNav > .headerText {
    margin-right: 0;
  }
  .mobileMainNav > .smallButtonWrap {
    margin-right: 3vw;
  }
  .mobileSideMenuWrapp {
    display: block;
    position: fixed;
    width: 55vw;
    top: 0.5px;
    left: 0.5px;
    border: 2px solid white;
    border-radius: 7px;
    z-index: 20000;
    background: rgba(13, 12, 19, 1);
  }

  .firstLineMobileWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 87%;
    margin-top: -3px;
    margin-left: 2.6vw;
  }
  .firstLineMobileWrapper > .headerText {
    margin-right: 0px;
  }
  .firstLineMobileWrapperContent {
    display: flex;

    align-items: center;
    justify-content: space-around;
  }
  .firstLineMobileWrapperContent > .headerText {
    margin-right: 0px;
  }
  .mobileSideMenuLastLine {
    margin-top: 10px;
    height: 80%;
    width: 85px;
    margin-left: 5.5vw;
    margin-bottom: 5vw;
  }
  .mobileSideMenuTextSecond {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: white;
    padding: 0;
    margin-left: 12px;
    margin-right: 12px;
  }

  .mobileSideMenuLastLine > .partTwosideMenuItem {
    position: relative;
  }
  .mobileSideMenuLastLine > .partTwosideMenuItem > .secondImgWrapper {
    padding: 10px;
  }
  .countriesMobileSideMenu {
    display: block;
  }
  .countriesMobileSideMenu > .sideMenuItem,
  .sideMenuItem-hovered {
    margin-left: 2.5vw;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst-active,
  .imgWrapperSideMenuFirst {
    padding: 8px;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst-active > img {
    width: 16px;
    height: 16px;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst > img {
    width: 15px;
    height: 15px;
  }

  .mapWrapper {
    margin-left: 0px;
    position: absolute;
    top: 0;
  }
  .mapBlock {
    width: 85%;
  }
}

.balanceTextReversed {
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-left: 12%;
}

.accountDataContainer {
  display: flex;
  align-items: center;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

input {
  outline: 0 !important;
}

.nameInput {
  outline: none !important;
  background: transparent;
  border: 1px solid #353840;
  border-radius: 6px;
  width: fit-content;
  color: white;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-right: 20px;
}

.nameInput::placeholder {
  color: white;
}
.searchText:active,
.searchText:hover,
.searchText:focus {
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: transparent;
}
.nameInput:active,
.nameInput:hover,
.nameInput:focus {
  border-radius: 6px;
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: white;
  width: fit-content;
}

.rev:active,
.rev:hover,
.rev:focus {
  border-radius: 6px;
  outline: 0;
  outline-offset: 0;
  --tw-ring-color: black;
  width: fit-content;
}
.open {
  width: fit-content !important;
}

.small {
  height: 43px;
  width: 43px;
}

.big {
  width: 125px;
}

.hidden {
  display: none;
}

.phInpWrapper {
  position: relative;
  width: 43px;
  height: 43px;
  background: linear-gradient(139.11deg, #6e4aee 2.01%, #a65df5 99.33%);
  border-radius: 7px;
  margin-right: 20px;
  display: flex;
}

.phInput {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.nameMeetingInputWrapper {
  background: white;
  border-radius: 12px;
  justify-content: space-around;
  width: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 90px;
  z-index: 2;
}

.nameMeetingInputWrapper:hover {
  border-color: rgb(69, 159, 189);
  box-shadow: 0px 0px 15px rgb(69, 159, 189);
  outline: 1px solid transparent;
}

.nameMeetingInput {
  border: 0;
  --tw-ring-color: '';
  padding-right: 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: black;
  background-color: transparent;
  width: 80%;
  height: max-content;
}
.nameMeetingInput:focus,
.nameMeetingInput:hover {
  --tw-ring-color: '';
  color: rgba(0, 0, 0, 0.3);
}

.meetNameImage {
  padding-right: 15px;
  padding-left: 15px;
}
.meetNameImage:hover {
  padding-right: 15px;
  padding-left: 15px;
  transform: scale(1.05);
}
.desktopNaw {
  display: flex;
}
.headerContentWpr {
  display: flex;
  width: 95%;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.contentForHeaderMeetingOn {
  display: flex;
  justify-content: start;
  height: 100%;
  align-items: center;
  padding-left: 3%;
  cursor: pointer;
}
.connectContainerMobile {
  display: none;
}
.arrowBackToMain {
  margin-right: 10px;
  fill: white;
}
.submitUsername {
  position: absolute;
  top: 30%;
  left: 77%;
}

.textAret {
  resize: none;
  outline: none;
  height: 55px;
  z-index: 200;
}

.textAret::placeholder {
  color: white;
}

.mobileBeforeConnect {
  display: none;
}

.mobileAfterConnect {
  display: none;
  align-items: center;
  /* width: 30vw;
  justify-content: space-between; */
}

/* .playVideoWrap {
  position: relative;
  width: 85px;
  height: 35px;
  margin-left: 20px;
  background: linear-gradient(92deg, #161616, #101010);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
} */
.playVideoWrap {
  width: 35px;
  height: 31px;
  top: 55px;
  left: 117px;
  border-radius: 8px;
  /* border: 1px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-right: 25px;
  background: linear-gradient(92deg, #161616, #101010);
  border: 1px white dashed;
}

@media screen and (max-width: 600px) {
  .playVideoWrap {
    margin-left: 16px;
  }
}

.playButtonHead {
  margin-left: 10px;
}
.playVideoWrap:hover > svg {
  scale: 1.2;
}

.playVideoWrap > span {
  font-family: 'SF Pro Display Medium';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.playVideoWrap:hover > span {
  scale: 1.2;
}
/* .playVideoWrap::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1.2px;
  background: linear-gradient(130deg, #565656, #101010 35%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
} */
.mobileBeforeConnect {
  display: none;
}
.videoContainerWrapp {
  background-color: rgba(0, 0, 0, 0.6);
}
.videoContainerWrapp > div {
  margin: 0 auto;
  margin-top: 5%;
  height: 70%;
  width: 70%;
}

@media screen and (max-width: 600px) {
  .videoContainerWrapp > div {
    width: 90%;
    height: 50%;
    margin-top: 15%;
  }
}
/* .mobileAfterConnect {
  display: none;
  width: 30vw;
  justify-content: space-between;
} */

.smallButtonWrap {
  display: flex;
  align-items: center;
  background: transparent;
  border: 2px solid #353840;
  color: white;
  height: 10vw;
  border-radius: 7px;
  width: 10vw;
  justify-content: center;
  cursor: pointer;
}

.smallButtonImg {
  margin-left: 0px;
}

.mobileMainNav {
  display: none;
}

.mobileSideMenuWrapp {
  display: none;
}

.imgLoaderGif {
  position: absolute;
}
@media screen and (min-width: 600px) {
  .imgLoaderGif {
    height: 75%;
    position: absolute;
  }
}
.connectContainer {
  padding: 25px;
  padding-right: 0px;
}
.waitingScreenDSKMB {
  height: 93vh;
}
.countriesMobileSideMenu {
  display: none;
}
@media screen and (max-width: 600px) {
  .searchWrapper {
    display: none;
  }
  .connectContainer {
    display: none;
  }
  .wrapperMobileBarFullPageWrapper {
    z-index: 0;
  }
  .connectContainerMobile {
    display: block;
  }
  .phInpWrapper {
    display: none;
  }
  .waitingScreenDSKMB {
    height: 76vh;
  }
  .bigNameInput {
    display: none !important;
  }

  .mobileBalanceTextReversed {
    padding-left: 2vw;
    color: black;
    font-size: 12px;
    font-family: Nunito;
    font-weight: 600;
    line-height: 23.494px;
    padding-right: 2vw;
    border-right: 1px solid #858e96;
  }
  .mobileBeforeConnect {
    display: flex;
    width: max-content;
    justify-content: space-between;
  }

  .mobileBalanceWrapper::before {
    content: '';
    /* position: absolute; */
    inset: 0;
    border-radius: 8.5px;
    padding: 1.5px;
    background: linear-gradient(160deg, #424242 0%, #2e2e2e 40%, rgba(82, 82, 82, 0) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  .imageWrapperMobile {
    border-radius: 5px;
    margin-left: 1vw;
    width: 100%;
    height: 100%;
    background: linear-gradient(139.11deg, #6e4aee 2.01%, #a65df5 99.33%);
  }
  .imgLoaderGif {
    height: auto;
    position: absolute;
  }
  .withContent {
    background: transparent;
  }

  .mobileAfterConnect {
    display: flex;
    align-items: center;
    /* width: 30vw;
    justify-content: space-between; */
  }
  .mobileMainNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .desktopNaw {
    display: none;
  }
  .mobileMainNav > .headerText {
    margin-right: 0;
  }
  .mobileMainNav > .smallButtonWrap {
    margin-right: 3vw;
  }
  .mobileSideMenuWrapp {
    display: block;
    position: fixed;
    width: 55vw;
    top: 0.5px;
    left: 0.5px;
    border: 2px solid white;
    border-radius: 7px;
    z-index: 20000;
    background: rgba(13, 12, 19, 1);
  }

  .firstLineMobileWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 87%;
    margin-top: -3px;
    margin-left: 2.6vw;
  }
  .firstLineMobileWrapper > .headerText {
    margin-right: 0px;
  }
  .firstLineMobileWrapperContent {
    display: flex;

    align-items: center;
    justify-content: space-around;
  }
  .firstLineMobileWrapperContent > .headerText {
    margin-right: 0px;
    color: white !important;
  }
  .mobileSideMenuLastLine {
    margin-top: 10px;
    height: 80%;
    width: 85px;
    margin-left: 5.5vw;
    margin-bottom: 5vw;
  }
  .mobileSideMenuTextSecond {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: white;
    padding: 0;
    margin-left: 12px;
    margin-right: 12px;
  }

  .countriesMobileSideMenu > .sideMenuItem,
  .sideMenuItem-hovered {
    margin-left: 2.5vw;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst-active,
  .imgWrapperSideMenuFirst {
    padding: 8px;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst-active > img {
    width: 16px;
    height: 16px;
  }
  .countriesMobileSideMenu > .sideMenuItem-hovered > .imgWrapperSideMenuFirst > img {
    width: 15px;
    height: 15px;
  }

  .mapWrapper {
    margin-left: 0px;
    position: absolute;
    top: 0;
  }
  .mapBlock {
    width: 85%;
  }

  .w3m-toolbar > img {
    margin-left: 60px !important;
  }
  .sectionSelectorContainer {
    width: 100% !important;
  }
  .searchWrapperM {
    display: flex;
  }
  .sectionSelectorBoxWrapper {
    padding-top: 15px !important;
  }
  .searchWrapperM::before {
    display: block !important;
  }
  .headerWrapper {
    overflow-x: hidden;
  }
}
