:root {
  --auth-btn-color: #6a49ee;
  --auth-btn-text-color: #ffffff;
}

.headerWrapperAuth {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.termsPolicy {
  color: #fff;
  text-align: center;
  font-family: 'SF Pro Display';
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.625rem;
  margin-bottom: 20px;
}

.auth__header--wrapper {
  width: 100%;
  z-index: 10000;
  height: 60px;
  min-height: 60px;
}
.auth__header--content {
  margin: 0 auto;
  width: 95%;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.auth--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 100vh;
  margin: 0 auto;
  justify-content: center;
  color: #ffffff;
}

.auth__user-data__title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.0625rem;
}
.auth__input--wrapper {
  width: 21.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

.auth__input--container {
  display: flex;
  border-radius: 0.5rem;
  background-size: 100%;
  border: 0.8px solid #fff;
  position: relative;
  background: linear-gradient(92deg, #181818 -8.9%, #111 115.57%);
  width: 100%;
}

.auth__user-data--input {
  width: 100%;
  background-size: 100%;
  background: linear-gradient(92deg, #181818 -8.9%, #111 115.57%);
  border-radius: 0.5rem;
  z-index: 5;
  border-left: none;
}
/* .auth__input--container ::before {
  background: linear-gradient(160deg, #424242, #2e2e2e 40%, rgba(82, 82, 82, 0));
  border-radius: 8.5px;
  content: '';
  inset: 0;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  position: absolute;
} */

.auth__user-data__input--wrapper {
  margin-top: 0.56rem;
}

.auth__user-data__button--wrapper {
  margin-top: 1.69rem;
}

.auth__user-data__button {
  display: flex;

  width: 100%;
  padding: 0.6875rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #6a49ee;
}

.auth__user-data__button--name {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 129.412% */
  letter-spacing: -1px;
}

.auth__user-oauth--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auth__user-data__connect-buttons--wrapper {
  width: 100%;
  margin-top: 1.12rem;
}
.auth__user-data__connect-buttons--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth__oauth-btn--wrapper {
  display: flex;
  width: 5.5625rem;
  height: 3.375rem;
  padding: 1.09313rem 0rem 1.06725rem 0rem;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5625rem;
  background: linear-gradient(108deg, #151515 2.76%, #111 128.83%);
  z-index: 1;
  color: white;
  height: 43px;
  border-radius: 7px;
  cursor: pointer;
}
.auth__oauth-btn--wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 7px;
  padding: 1px;
  background: linear-gradient(120deg, #2c2c2c 20%, #111 80%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
/* -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

/* .auth__oauth-btn--wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8.5px;
  padding: 1px;
  background: linear-gradient(160deg, #424242 0%, #2e2e2e 40%, rgba(82, 82, 82, 0) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
} */
.auth__user-data__divider--wrapper {
  display: flex;
}

.auth__user-data__divider-title {
  color: rgba(255, 255, 255, 0.7);
  font-family: SF Pro Display;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 1.01563rem */
  letter-spacing: 0.01625rem;
}

.auth__user-data__diver-line {
  width: 6.8125rem;
  height: 0.0625rem;
  background: #1c1c1c;
}

.auth__user-data__inputWrapper--domain {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.upload-avatar--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .auth__header--wrapper {
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    height: 60px;
    min-height: 60px;
  }
  .auth__header--content {
    margin: 0 auto;
    width: 95%;
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .auth--wrapper {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100vh;
    margin: 0 auto;
    justify-content: c;
    color: #ffffff;
  }

  .auth__user-data__title {
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0625rem;
  }
  .auth__input--wrapper {
    width: 21.5rem;
    height: 2.5rem;
    flex-shrink: 0;
  }

  .auth__input--container {
    display: flex;
    border-radius: 0.5rem;
    background-size: 100%;
    border: 0.8px solid #fff;
    position: relative;
    background: linear-gradient(92deg, #181818 -8.9%, #111 115.57%);
    width: 100%;
  }

  .auth__user-data--input {
    width: 100%;
    background-size: 100%;
    background: linear-gradient(92deg, #181818 -8.9%, #111 115.57%);
    border-radius: 0.5rem;
    z-index: 5;
    border-left: none;
  }
  /* .auth__input--container ::before {
    background: linear-gradient(160deg, #424242, #2e2e2e 40%, rgba(82, 82, 82, 0));
    border-radius: 8.5px;
    content: '';
    inset: 0;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    position: absolute;
  } */

  .auth__user-data__input--wrapper {
    margin-top: 0.56rem;
  }

  .auth__user-data__button--wrapper {
    width: 17rem;
    margin-top: 1.69rem;
  }

  .auth__user-data__button {
    display: flex;

    width: 100%;
    padding: 0.6875rem 0rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #6a49ee;
  }

  .auth__user-data__button--name {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px; /* 129.412% */
    letter-spacing: -1px;
  }

  .auth__user-oauth--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .auth__user-data__connect-buttons--wrapper {
    width: 100%;
    margin-top: 1.12rem;
  }
  .auth__user-data__connect-buttons--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .auth__oauth-btn--wrapper {
    display: flex;
    width: 5.5625rem;
    height: 3.375rem;
    padding: 1.09313rem 0rem 1.06725rem 0rem;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.5625rem;
    background: linear-gradient(108deg, #151515 2.76%, #111 128.83%);
    z-index: 1;
    color: white;
    height: 43px;
    border-radius: 7px;
    cursor: pointer;
  }
  .auth__oauth-btn--wrapper::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 7px;
    padding: 1px;
    background: linear-gradient(120deg, #2c2c2c 20%, #111 80%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  .auth__user-data__divider--wrapper {
    display: flex;
  }

  .auth__user-data__divider-title {
    color: rgba(255, 255, 255, 0.7);
    font-family: SF Pro Display;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 1.01563rem */
    letter-spacing: 0.01625rem;
  }

  .auth__user-data__diver-line {
    width: 6.8125rem;
    height: 0.0625rem;
    background: #1c1c1c;
  }

  .auth__user-data__inputWrapper--domain {
    display: flex;
    align-items: baseline;
    gap: 5px;
    width: 17rem;
  }
  .upload-avatar--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
