@import url(https://db.onlinewebfonts.com/c/179429c3795fb9aed42c526bd1133cc0?family=SF+Pro+Display+Semibold);
.link {
    width: 13.25%;
    height: calc((100vw * 0.157) / 1.8);
    background-color: transparent;
    /* border: 0.85px solid rgba(255, 255, 255, 1); */
    background: linear-gradient(126deg, #1e1e1e 0%, rgba(82, 82, 82, 0.00) 100%);
    border-radius: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    position: relative;
}
.freee-card{
    margin-bottom: 15px;
}
.link::before{
     content: "";
  position: absolute;
  inset: 0;
  border-radius: 8.5px; 
  padding: 1px; 
  background:linear-gradient(160deg,  #424242 0% ,#2e2e2e 40% ,rgba(82, 82, 82, 0.00) 100%); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
/* .link:nth-child(2n+1), .link__recieved:nth-child(2n+1), .link__defaultImage:nth-child(2n+1) {
    margin-left: 20px;
    margin-right: 0px!important;
} */

.link--paid {
   
 
    background-color: transparent;
    border: 0.85px dashed rgba(255, 255, 255, 0.38);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
}

.link--paid:hover {
    transform: scale(1.05);
    border: 1px dashed;
    border-color: rgba(172, 95, 246, 1);
    z-index: 100;
    padding: 15px;
}

.link__defaultImage {
   width: 13%;
    position: relative;
    height: calc((100vw * 0.155) / 1.8);
    height: -moz-calc((100vw * 0.155) / 1.8);
    height: -webkit-calc((100vw * 0.155) / 1.8);
    background-color: transparent;
    
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    background: linear-gradient(126deg, #1e1e1e 0%, rgba(82, 82, 82, 0.00) 100%);
    border-radius: 8.5px;
    position: relative;
}

.link__defaultImage--paid {
    width: 48.5%;
    position: relative;
    height: calc(15vw / 1.9);
    height: -webkit-calc(15vw / 1.9);
    background-color: transparent;
    border: 0.85px dashed rgba(255, 255, 255, 1);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    margin-bottom: 15px;
}


.link__recieved {
    width: 13%;
    height: calc((100vw * 0.155) / 1.8);
    height: -moz-calc((100vw * 0.155) / 1.8);
    height: -webkit-calc((100vw * 0.155) / 1.8);
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    background-color: transparent;
}

.link__recieved  {
    width: 13.25%;
    height: calc((100vw * 0.157) / 1.8);
    height: -moz-calc((100vw * 0.157) / 1.8);
    height: -webkit-calc((100vw * 0.157) / 1.8);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    background-color: transparent;
   
}


@media screen  and (max-width:768px){
    .link__recieved{
        margin-bottom: 15px;
    }
    .link{
        margin-bottom: 15px;
    }
}
/* .link__recieved:hover{
    width: 15.5%;
    height: calc((100vw * 0.157) / 2);
    position: relative;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    background-color: transparent;
    margin-right: 15px;
    transform: scale(1.05);
} */
.link__recieved--paid{

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
    background-color: transparent;
    margin-right: 15px;
    height: -webkit-calc(15vw / 1.9);
}

.link__recieved:hover{
    transform: scale(1.05);
    z-index: 100;
}

.by-one{
    width: 15.5% !important;
}
.meetingSettings{
    width: 55.5% !important;
}

.link--pink{
    background-color: transparent;
    border: 0.85px dashed rgba(173, 0, 255, 1);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
}

.link--pink:hover {
    transform: scale(1.05);
    z-index: 100;
}


.link--purple{
    background-color: transparent;
    border: 0.85px dashed rgba(0, 163, 255, 1);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100%;
}

.link--purple:hover {
    transform: scale(1.05);
    z-index: 100;
}

.name {
    display: block;
    color: white;
}

.link:hover, .link__defaultImage:hover, .link__defaultImage--paid:hover{
    transform: scale(1.05);
    /* border: 2px solid white; */
    
    z-index: 100;
    padding: 10px;
     /* animation: skeleton-loading 1s linear infinite alternate; */
}
.link:hover::before , .link__defaultImage:hover::before{
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 9px; 
  padding: 1.5px; 
  background:linear-gradient(148deg,  #5E45EC ,#EC75FF); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}


.textField {
    margin-top: 5px;
    text-align: center;
    font-family: "SF Pro Display Semibold";
    font-size: 14px;
    font-style: normal;
    color: white;
    font-weight: 700;
    z-index: 2;
}
.textField__recieved {
    position: absolute;
    top: -0px;
    font-family: "SF Pro Display Medium";
    font-style: normal;

    /* left: 50%; */
    /* transform: translate(0%, -50%); */
    /* font-family: 'Roboto'; */
    font-weight: 500;
    font-size: 8px;
    line-height: 19px;
    /* fill: #aaadbe;
    color: black; */
     color: black;
    border-radius: 9px;
    /* max-width: 166px; */
    /* width: 90%; */
    display: flex;
    padding-left: 20px;
    line-height: 1.3em;
    padding: 5px 8px;
    text-align: left;
    background: linear-gradient(148deg,#FFF, #D9D9D9 );
    width: 90%;
}

.textField__recieved::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 9px; 
  padding: 1.5px; 
  background:linear-gradient(148deg,#D9D9D9 100%, #D9D9D9 60%); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
@-webkit-keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}
@keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}
.cardData__recieved {
    object-fit: cover;
    width: 100%;

    height: calc(15vw / 1.8);
    border-radius: 8.5px;
    
}
/* .freee-card{
     -webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
-webkit-animation-name: fadeIn;
animation-name: fadeIn;
} */
.cardHeader{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    justify-items:center;
}
.streamPrice{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 550;
    font-size: 10.2439px;
    line-height: 16px;
    letter-spacing: -0.270954px;
    color: #FFFFFF;
}
.streamPriceBox{
    position: absolute;
    display: flex;
    border-radius: 7px;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 4px;
    padding: 5px;
}
.streamPriceBox--pink{
    display: flex;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 4px;
    padding: 5px;
    border: 1px solid rgba(173, 0, 255, 1);
}
.streamPriceBox--purple{
    display: flex;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 4px;
    padding: 5px;
    border: 1px solid rgba(0, 163, 255, 1);
}

.link__recieved > .cardHeader{
    width: 100%;

}

.link__recieved > .cardHeader > .cardData__recieved{
    object-fit: cover;
    width: 100%;
}

@media screen  and (max-width:768px){
    .textField__recieved{
        font-size: 9px;
        top: -10px;
        font-weight: 500;
    }
    .onlineAvatarWrapper{
        width: 24px;
        height: 24px;
    }
    .textField{
        font-size: 13px;
    }
}

/* @keyframes slideRight {
    0% {
        transition:  width 2s,
    height 2s,
    background-color 2s,
    transform 2s;
    }
   
    100% {
        transition: translateX(0%);
    }   
} */
 
/* @-webkit-keyframes slideRight {
    0% {
        -webkit-transform: translateX(-10%);
    }
   
    100% {
        -webkit-transform: translateX(0%);
    }
} */

.imageCard{
  
}