@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

#root{
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div .button {
  margin: 0 15px 15px 0;
}
.video-contain video {
  object-fit: contain;
  overflow: hidden;
}
textarea:focus {
  outline: none;
  border-color: none;
  box-shadow: none;
}

input:focus-visible {
  outline: none;
  border-color: none;
  box-shadow: none;
}

.video-cover video {
  position: absolute;
  object-fit: cover;
}

input[type='radio']:checked + label span {
  background-color: #fff;
  box-shadow: 0px 0px 0px 2px black inset;
}

input[type='radio']:checked + label {
  color: #fff;
}

.flying-emojis {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  z-index: 99;
}
.emoji {
  position: absolute;
  bottom: 0px;
  left: 50%;
  font-size: 48px;
  line-height: 1;
  width: 48px;
  height: 48px;
}
.emoji.wiggle-1 {
  animation: emerge 3s forwards, wiggle-1 1s ease-in-out infinite alternate;
}
.emoji.wiggle-2 {
  animation: emerge 3s forwards, wiggle-2 1s ease-in-out infinite alternate;
}
@keyframes emerge {
  to {
    bottom: 85%;
    opacity: 0;
  }
}
@keyframes wiggle-1 {
  from {
    margin-left: -50px;
  }
  to {
    margin-left: 50px;
  }
}
@keyframes wiggle-2 {
  from {
    margin-left: 50px;
  }
  to {
    margin-left: -50px;
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.carousel-item img {
  display: block;
  height: auto;
  max-width: 100%;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 30px;
  height: 35px;
  line-height: 30px;
  position: absolute;
  top: 25%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 35px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

input:active,
input:focus {
  outline: none !important;
  border: grey !important;
}
input::-moz-focus-inner {
  border: 0 !important;
}

.magicBoxIcon {
  background: linear-gradient(131.58deg, #240a51 4.63%, #5201c8 103.21%);
  border-radius: 12px;
  cursor: pointer;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin: 0.5rem;
}

.magicBoxIcon--lazyload {
  border-radius: 12px;
  cursor: pointer;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  background-color: rgb(38, 38, 43);
}

.preloadingBox {
  height: 44px;
  left: 617px;
  top: 762px;
  background: #1c1c23;
  border-radius: 12px;
  margin: 0.5rem;
}

.magicBoxIcon:hover > img {
  scale: 1.1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w3m-toolbar > img,
.w3m-toolbar > svg {
  height: 100% !important;
}

:picture-in-picture {
  opacity: 0.3;
  filter: blur(5px);
}

@layer base {
  img,
  video {
    max-width: initial;
    height: initial;
  }
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./fonts/sf-pro-display_regular.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Black';
  src: local('SF Pro Display Black'), url(./fonts/sfBlack.woff2) format('woff2'),
    url('https://db.onlinewebfonts.com/t/7a6f2630cebd5e0ea712848621f9502e.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display Semibold';
  src: local('SF Pro Display Semibold'), url(./fonts/sfSemi.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url('https://db.onlinewebfonts.com/t/3cff9206b4c8d578245ef1aaeeaf9e84.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  src: local('SF Pro Display Medium'), url(./fonts/sf-pro-display_medium.woff2) format('woff2');
}

@keyframes dissapearSmooth {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

#myelement {
  animation-name: myAnimation;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}
