.meetingMainWindowWrapper {
  height: 100vh;
}
.magicBtn {
  margin-right: 8%;
}

.mobileMeetingStretch {
  height: 93vh;
}

@media screen and (max-width: 600px) {
  .meetingMainWindowWrapper {
    height: 76vh;
  }
  .meetNameWrapperForMargin {
    width: 85vw !important;
  }
  .magicBoxIcon {
    margin-right: 0px;
    height: 43px;
  }
  .magicBtn {
    margin-right: 0px !important;
    /* height: 75%; */
    width: 55px;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .contentMobileWrapper {
    margin-left: 0px;
    width: 100% !important;
  }
  .bottomPanelButtons {
    z-index: 10000;
    transform: translateY(-14.2%);
  }
}
