.appka {
  width: 100%;
  top: 10px;
  z-index: 20000;
  position: absolute;
  opacity: 0;
  }

  @media screen  and (max-width:600px){
    .appka{
      width: inherit;
    }
  }
  /* .connectWrapper::before{
     content: '';
  position: absolute;
  display: block;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  } */