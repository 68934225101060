.friendSelectorBox{
    align-self: center;
    width: 43px;
    height: 43px;
    background: linear-gradient(139.11deg, #6E4AEE 2.01%, #A65DF5 99.33%);
    border-radius: 7px;
    margin-right: 5px;
    margin-top: 2px;
}
.friendSelectorImage{
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;
}