.onlineCounterBoxWrapper{
    display: flex;
    justify-content: center;
    padding-top: 25px;
    margin-bottom: 10px;

}
.onlineCounterBoxWrapperPaid{
    display: flex;
    justify-content: center;
    padding-top: 25px;
    margin-bottom: 10px;
    width: calc(100% - 85px);
    width: -moz-calc(100% - 85px);
    width: -webkit-calc(100% - 85px);
    margin-left: 85px;
}

.onlineCounterBox{
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
width: 110px;
height: 35px;
left: 788px;
top: 95px;
border: 1px solid #AD00FF;
border-radius: 7px;
}

.onlineCounterContent{
    display: flex;
}

.onlineLogoSvg{
    padding-right: 5px;
}

.onlineText{

font-weight: 500;
font-size: 15px;
line-height: 23px;
font-family: 'Poppins';
font-style: normal;
letter-spacing: -0.396754px;
color: #FFFFFF;
}
.onlineCount{
margin-left: 7px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 23px;
letter-spacing: -0.396754px;
color: #757D89;
}