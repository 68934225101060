.amountSelectorWrapper{
    width: 95%;
    background: linear-gradient(131.58deg, #240A51 4.63%, #5201C8 103.21%);
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 7px;
    padding-bottom: 20px;

}

.amountButton__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
}

.amountButton__rowWrapper{
    display: flex;
    width: 100%;
    gap: 5px;
    margin-bottom: 5px;
    justify-content: center;
}

.amountButton__selector{
    display: flex;
    width: 60px;
    height: 55px;
    letter-spacing: 0.5px;
    background-color: #E0314B;
    color: white;
    font-size: 13px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
}
.active{
    border: 1px solid #FFFFFF;
}

.amountButton__priceInfo{
    display: flex;
    flex-direction: column;
    padding: 2px;
}
.amountButton__amount{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    background: rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(1px);
    border-radius: 9px;
    padding: 3px;
}

.walletsConnectedWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
}

/*amount selector*/

.amountSelected__backgroundWraper{
    position: absolute;
    width: 2.3rem;
    height: 1.4rem;
    right: 1%;
    top: 1%;
    flex-shrink: 0;
}

.amountSelected__backgroundLayer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-6.772deg);
    background: rgba(0, 0, 0, 0.9);
    border-radius: 25px;
    backdrop-filter: blur(8px);
}

.amountSelected__content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: rotate(7deg);
    padding: 10px;
    color: white;
}

.amountSelected__text{
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0525rem;
    text-transform: uppercase;
}