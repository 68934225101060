.mobileBalanceText {
  color: white;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.46838rem; /* 195.781% */
  letter-spacing: 0.0075rem;
}

.balanceWrapper {
  width: 8rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.connectButtonText {
  top: 7px;
  left: 46px;
  font-family: 'SF Pro Display Black';
  font-size: 17px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: -1px;
  text-align: left;
  margin-left: 7px;
}

.connectButtonContent {
  display: flex;
  align-items: center;
  padding: 7px 22px;
}

.connectButtonImage {
}
