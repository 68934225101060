.meetingCards{
    display: flex;
    width: 100%;
    height: fit-content;
    padding-top: 10px;
    justify-content: space-between;
}
.meetingCardsSearch{
    display: flex;
    width: 95%;
    margin: 0 auto;
    flex-wrap: wrap;
    height: fit-content;
    padding-top: 10px;
    justify-content: flex-start;
}


.meetingCards::-webkit-scrollbar {
  display: none;
}
.meetingsCardsRowHeadline{
    padding-left: 2%;
    font-family: "Nunito";
    font-weight: 800;
    color: #fff;
    font-size: 25px;
    line-height: 35px;
    margin-block: 0;
}

.firstWrapper{
    margin-top: 10px;
}
.secondWrapper{
    padding-bottom: 20px;
}

.meetingCardsSearch > .link__recieved:nth-child(n){
    margin-right: 15px;
    margin-bottom: 12px;
}