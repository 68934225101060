.userDataInputWrapper {
  cursor: pointer;
  display: none;
  align-items: center;
  background: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: white;
  height: 43px;
  border-radius: 7px;
  width: 17rem;

  margin: 0 auto;
  margin-top: 20px;
  /* margin-left: 42%; */
  background: linear-gradient(148deg, #181818 0%, #111 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.userDataInputWrapper {
  display: flex;
}
.userDataInputWrapper::before {
  display: block !important;
}

.arrowImg {
  margin-right: 10px;
}
