.sendTransactionButton{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 23px;
    color: #4204A0;
}