/* timerStyles.css */
.timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 240px;
    background-color: rgba(255, 255, 255, 1);
    align-self: center;
    border-radius: 10px;
  }
  .timer__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .timer-title {
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .timer-subtitle {
    font-size: 10px;
    margin-bottom: 10px;
  }
  
  .timer {
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timer-hours,
  .timer-minutes,
  .timer-seconds {
    padding: 0 4px;
    font-size: 50px;
  }
  
  .timer-status {
    font-size: 16px;
  }
  
  .button-container {
    font-size: 12px;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    color: black;
    width: 100%;
    padding: 5px 15px;
  }
  
  .timer-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .timer-button:hover {
    background-color: #0056b3;
  }
  
  .timer-button:active {
    background-color: #003f80;
  }

  .timer-background{
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 13px 20px;
    width: 85%;
  }
  .timer-converter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  /* .timer-converter-input {
    margin: 10px 0;
  } */
  
  .timer-converter-input label {
    color: #ffffff;
    font-size: 18px;
    margin-right: 10px;
  }
  
  .timer-converter-input input {
    width: 70px;
    padding: 5px;
    font-size: 50px;
    border: none;
    border-radius: 4px;
  }
  
  .timer-converter-button {
    background-color: #3b82f6;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .timer-converter-result {
    color: #ffffff;
    font-size: 24px;
    margin-top: 20px;
  }
  