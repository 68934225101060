.meetingCardsAllWrapperPaid{
  width: 100%;
}
.meetingCards::-webkit-scrollbar {
  display: none;
}
.meetingsCardsRowHeadline{
    padding-left: 2%;
    font-family: "Nunito";
    font-weight: 800;
    color: #fff;
    font-size: 25px;
    line-height: 35px;
    margin-block: 0;
}

.paidRoomsPageWrapper{
    margin-top: 30px;
    width: -webkit-calc(100% - 85px);
    margin-left: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paidRoomsContentWrapper{
    display: flex;
    width: calc(100% - 100px);

    margin-top: 20px;
   
    margin-bottom: 20px;
}
.paidRoomsPricings:first-child{
    margin-left: 15px;
}
.mainPriceWrapper{
    color: white;
    width: 95%;
    height: 100px;
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}

.paidRoomsPricings{
   width: 32%;
   margin-right: 15px;
}

.link--pink , .link--purple, .link--paid{
width: 48.5%;
position: relative;
margin-bottom: 15px;
height: calc(15vw / 1.9);
height: -moz-calc(15vw / 1.9);
height: -webkit-calc(15vw / 1.9);
}

.link__recieved--paid{
    width: 47%;
}

.paidRoomMeetingCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardData__recieved--paid {
    max-width: 100%;
    object-fit: cover;
    height: calc(15vw / 1.9);
    height: -moz-calc(15vw / 1.9);
    height: -webkit-calc(15vw / 1.9);
    border-radius: 8.5px;
}