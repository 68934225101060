.contentWrapperMainPage {
  display: flex;
  flex-direction: column;
}

/* .freee-card{
    margin-right: 19px;
} */
/* 
@media screen and (max-width: 600px){
    .sideMenuMainPage{
        display: none;
    }
    .mainViewMainPage{
        width: 100%!important;
        margin-left: 0!important;
    }
    .meetingCardsAllWrapper{
        padding: 0!important;
    }
    .meetingCards{
        padding: 0!important;
        height: 100px!important;
    }
    .meetingsCardsRowHeadline{
        padding-left: 10px!important;
        padding-top: 10px;
    }
    .cardData{
        height: 70.5px!important;
        min-width:130px!important;
    }
    .footer{
        width: 100%!important;
        margin-left: 0!important;
    }
    .logoBlock123{
        height: 35px!important;
        margin-top: 15px;
    }

    .otherLastButtons{
        display: none!important;
    }
    .likedButton{
        display: none!important;
    }
    .logo-revert{
        display: none!important;
    }
    .logoBlock{
    display: flex;
    justify-content: center;
    }
    .mainFooterLinks{
        width: 89%;
        margin: 0 auto;
    }
    .contentMobileWrapper{
        width: 100%;
        margin: 0;
       
    }
    .headerContent{
        justify-content: space-around;
        width: 85%;
    }
} */

.closedMenu {
  width: 60px;
}

.mainViewMainPage {
  width: 100%;
  overflow-x: hidden;
  /* margin-top: 10px; */
  margin-bottom: 34px;
}

.firstPartSideMenu {
  width: 100%;
  border-bottom: 1px solid #28293d;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.firstPartSideMenu:first-child {
  margin-top: 15px;
}

.firstpartNewItem {
  padding: 13px;
  border-radius: 8px;
  background: #222328;
  margin-top: 5px;
  margin-bottom: 5px;
}

.firstpartNewItem > img {
  height: 15px;
  width: 15px;
}
.sideMenuItem {
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  align-items: center;
}
.sideMenuItem-hovered {
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  align-items: center;
  margin-left: 23px;
}

.imgWrapperSideMenuFirst {
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #222328;
  background-color: #222328;
}
.imgWrapperSideMenuFirst-active {
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #764def !important;
  background-color: #222328;
}
.imgWrapperSideMenuFirst-hovered {
  padding: 10px;
  border-radius: 8px;
  background-color: #222328;
  border: 2px solid black;
}
.sideMenuTextqwe {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: white;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0;
}
.sideMenuTextSecond {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: white;
  margin-left: 25px;
  margin-right: 25px;
  padding: 0;
}
.wrapSecondPartSideMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid #28293d;
  height: 100%;
  gap: 20px;
}

.trueMenu {
  width: 200px;
}
.sideMenuText {
  font-size: 15px;
  font-weight: 600;
  color: white;
  font-family: 'Nunito';
}

.imgWrapperSideMenuFirst > img {
  height: 15px;
  width: 15px;
}
.footer {
  width: calc(100% - 85px);
  width: -moz-calc(100% - 85px);
  width: -webkit-calc(100% - 85px);
  margin-left: 85px;
  border-top: 1px solid #28293d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerBig {
  width: 100%;
  border-top: 1px solid #28293d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoBlock123 {
  height: 80px;
  display: flex;
  justify-content: center;
}

.footerTextBlock {
  width: 90%;
  color: #aaadbe;
  padding: 30px 30px 30px;
  font-size: 16px;
  font-family: 'Nunito';
  border-radius: 10px;
  background-color: rgb(19, 20, 30);
  margin: 16px;
}

.footerLinks {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #28293d;
}
.mainFooterLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.mainFooterLink1 {
  color: #878a9e;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-top: 2px;
}

.socialLinks {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  margin-top: 15px;
}

.socialLinks > span {
  color: white;
  padding-left: 15px;
  font-size: 18px;
  font-family: Nunito;
  font-weight: 800;
  cursor: pointer;
}

.socialLogo {
  cursor: pointer;

  height: 15px;
  width: 15px;
}

.socialLogoWrapper {
  border: 1px solid #878a9e;
  margin-left: 15px;
  padding: 8px;
  border-radius: 50%;
}
.socialLogoWrapper:hover {
  border: 1.2px solid rgb(104, 66, 255);
}

.testSideMenu {
  height: calc(100vh - 60px);
  height: -moz-calc(100vh - 60px);
  height: -webkit-calc(100vh - 60px);
  width: 60px;
  border-right: 1px solid red;
  position: absolute;
}

.wrapperForAllPage {
  width: calc(100% - 60px);
  width: -moz-calc(100% - 60px);
  width: -webkit-calc(100% - 60px);
  margin-left: 60px;
}

/* .selected{
    border-left: 5px solid #A48EFF;
    color:#A48EFF;
    background-color: transparent;
  } */

.selected > p {
  color: #a48eff;
}

.mapBlock {
  width: 60%;
  height: fit-content;
  position: absolute;
  z-index: 2000;
  border: 1px solid white;
  background: black;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10001;
}

.mapImage {
  padding: 30px;
  padding-bottom: 0px;
}
.mapWrapper {
  margin-left: 85px;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.76);
}
